import { getAuthToken } from '@/utils/auth'
import HttpRequest from './http-request'

class TrackProvider extends HttpRequest {
  getTrack(payload) {
    this.setHeader(getAuthToken())

    return this.get('track', payload)
  }

  createTrack(payload) {
    this.setHeader(getAuthToken())

    return this.post('track', payload)
  }

  createTrackUpload(payload) {
    this.setHeader(getAuthToken())

    return this.post('track/upload', payload)
  }

  updateTrack(id, payload) {
    this.setHeader(getAuthToken())

    return this.put(`track/${id}`, payload)
  }

  getTrackById(id) {
    this.setHeader(getAuthToken())

    return this.get(`track/${id}`)
  }

  deleteTrack(id) {
    this.setHeader(getAuthToken())

    return this.delete(`track?id=${id}`)
  }

  getTags() {
    this.setHeader(getAuthToken())

    return this.get('track/tags')
  }
}

export default TrackProvider
