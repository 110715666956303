import { getAuthToken } from '@/utils/auth'
import HttpRequest from './http-request'

class MasterDataProvider extends HttpRequest {
  getLangs() {
    this.setHeader(getAuthToken())

    return this.get('master/langs')
  }
}

export default MasterDataProvider
