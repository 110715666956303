<template>
  <v-row class="match-height">
    <v-col cols="12">
      <v-card>
        <v-card-title v-if="routeName === 'add'">
          Add Video
        </v-card-title>
        <v-card-title v-if="routeName === 'update'">
          Update Video
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validForm"
            :lazy-validation="false"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="12 "
              >
                <v-text-field
                  v-model="form.url"
                  label="Youtube Url"
                  :rules="rules.url"
                  :readonly="routeName !== 'add'"
                  outlined
                  dense
                  placeholder="Youtube url"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  label="Type"
                  value="youtube"
                  readonly
                  outlined
                  dense
                  placeholder="Type"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="form.lang"
                  label="Language"
                  :items="langs"
                  :rules="rules.lang"
                  :readonly="routeName !== 'add'"
                  item-value="code"
                  item-text="name"
                  outlined
                  dense
                  placeholder="Language"
                />
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="form.alternativeLangs"
                  label="Alternative Language"
                  :readonly="routeName !== 'add'"
                  :items="langs"
                  item-value="code"
                  item-text="name"
                  outlined
                  multiple
                  dense
                  placeholder="alternative Language"
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="form.note"
                  label="Note"
                  outlined
                  dense
                  placeholder="Note"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-combobox
                  v-model="form.tags"
                  outlined
                  dense
                  :append-icon="'mdi-pencil-outline'"
                  hide-selected
                  hint="Press Enter for add some tags."
                  label="Tags"
                  multiple
                  small-chips
                >
                </v-combobox>
              </v-col>

              <v-col cols="12">
                <v-btn
                  v-if="routeName === 'add'"
                  color="primary"
                  :loading="loading"
                  @click="loading ? () => {} : createTrack()"
                >
                  Submit
                </v-btn>
                <v-btn
                  v-if="routeName === 'update'"
                  color="primary"
                  :loading="loading"
                  @click="loading ? () => {} : updateTrack()"
                >
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MasterDataProvider from '@/resources/master-data'
import TrackProvider from '@/resources/track'

const MasterDataService = new MasterDataProvider()
const TrackService = new TrackProvider()
export default {
  data() {
    return {
      loading: false,
      form: {
        url: '',
        type: 'youtube',
        lang: '',
        alternativeLangs: [],
        tags: [],
        note: ''
      },
      validForm: false,
      rules: {
        lang: [
          v => !!v || 'Please fill the Language.'
        ],
        url: [
          v => !!v || 'Please fill the Url.',
          v => /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/.test(v) || 'Invalid youtube url'
        ]
      },
      langs: []
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    id() {
      return this.$route.params.id || null
    }
  },
  async mounted() {
    await this.getlang()
    await this.getData()
  },
  methods: {
    async getlang() {
      const { data } = await MasterDataService.getLangs()
      Object.keys(data).forEach(k => {
        this.langs.push({
          code: k,
          name: data[k]
        })
      })
    },
    async getData() {
      if (this.routeName !== 'add') {
        const { data } = await TrackService.getTrackById(this.id)
        this.form = {
          url: data.url,
          type: 'youtube',
          lang: data.lang,
          alternativeLangs: data.alternativeLangs,
          tags: data.tags,
          note: data.note
        }
      }
    },
    async updateTrack() {
      this.$refs.form.validate()
      if (!this.validForm) return
      try {
        this.loading = true
        const result = await TrackService.updateTrack(this.id, this.form)
        if (result) {
          this.$store.dispatch('Style/setSnackbar', {
            message: 'Success',
            type: 'success',
            active: true
          })

          this.$router.push({ name: 'trackList' })
        }
      } catch (err) {
        this.$store.dispatch('Style/setSnackbar', {
          message: `Error ${err.message}`,
          type: 'pink',
          active: true
        })
      } finally {
        this.loading = false
      }
    },
    async createTrack() {
      this.$refs.form.validate()
      if (!this.validForm) return
      try {
        this.loading = true
        const result = await TrackService.createTrack(this.form)
        if (result) {
          this.$store.dispatch('Style/setSnackbar', {
            message: 'Success',
            type: 'success',
            active: true
          })
          this.$router.push({ name: 'trackList' })
        }
      } catch (err) {
        console.log(err)
        this.$store.dispatch('Style/setSnackbar', {
          message: `Error ${err}`,
          type: 'pink',
          active: true
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
